"use strict";
'use es6';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("sporks!../bundles/before.js");

var _hsPromiseRejectionTracking = require("hs-promise-rejection-tracking");

var _hubspot = _interopRequireDefault(require("hubspot"));

if (process.env.NODE_ENV !== 'production') {
  if (_hubspot.default.define === _hubspot.default.defineEager) {
    throw new Error('`head-dlb` is incompatible with HUBSPOT_DEFINE_EAGER override');
  }
}

(0, _hsPromiseRejectionTracking.enableRejectionTracking)(); // `useGlobals` override to make sure these legacy modules don't create globals

_hubspot.default.modules.useGlobals = function (ns) {
  return ns !== 'raven-hubspot/configure' && ns !== 'PortalIdParser' && ns !== 'hubspot.PortalIdParser' && ns !== 'enviro' && ns !== 'q';
}; // backwards compatibility legacy modules for use in script tags
// since we're generating simple manifests this `hubspot.define`
// won't get statically analyzed so that fixes the build error about
// 'raven-hubspot/configure' being provided by both head-dlb@dev
// and raven-hubspot@some-version
// currently sentry configuration logic depends on this being a legacy module


_hubspot.default.define('raven-hubspot/configure', [], function () {
  return require('raven-hubspot/configure');
}); // `mixin newRelicRUMandErrorMonitoringJS` depends on this legacy module


_hubspot.default.define('PortalIdParser', [], function () {
  return require('PortalIdParser');
});

_hubspot.default.define('hubspot.PortalIdParser', [], function () {
  return require('PortalIdParser');
}); // `mixin newRelicRUMandErrorMonitoringJS` and `hubspot-dlb` depend on this being a legacy module


_hubspot.default.define('enviro', [], function () {
  return require('enviro');
}); // temporary fix to fix blocked module from `hub-http`
// See https://git.hubteam.com/HubSpot/hub-http/blob/7b9702eeff2bff55e906f217638b49750ee3a86f/static/js/helpers/promise.js#L5-L7


_hubspot.default.define('q', [], function () {
  return require('q');
}); // We use `defineProperty` to create the globals so we can have hook into tracking usage when we choose to deprecate
// backwards compatibility for libraries/apps that assume window.Raven.


Object.defineProperty(window, 'Raven', {
  get: function get() {
    return require('Raven');
  }
});
Object.defineProperty(_hubspot.default, 'PortalIdParser', {
  get: function get() {
    return require('PortalIdParser');
  }
});
Object.defineProperty(window, 'enviro', {
  get: function get() {
    return require('enviro');
  }
});